import { createClient } from '@supabase/supabase-js';

const supabaseUrl = process.env.REACT_APP_SUPABASE_URL!;
const supabaseAnonKey = process.env.REACT_APP_SUPABASE_ANON_KEY!;

if (!supabaseUrl || !supabaseAnonKey) {
    console.error('Missing Supabase configuration:', { 
        url: supabaseUrl ? 'set' : 'missing', 
        key: supabaseAnonKey ? 'set' : 'missing' 
    });
}


const supabase = createClient(
    supabaseUrl || '', 
    supabaseAnonKey || '', 
   
);

// Set up auth configuration
supabase.auth.setSession({
    access_token: '',
    refresh_token: ''
});

// Configure the redirect URL in auth settings
supabase.auth.onAuthStateChange((event, session) => {
    if (event === 'SIGNED_OUT') {
        window.location.href = 'https://ontrakk.com/login';
    }
});

export default supabase;