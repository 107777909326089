// src/theme.ts
import { createTheme } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#1976d2', // Primary Blue
    },
    secondary: {
      main: '#ff9800', // Secondary Orange
    },
    background: {
      default: '#f4f4f4', // Light Grey
      paper: '#ffffff', // White
    },
    text: {
      primary: '#333333', // Dark Grey
      secondary: '#555555', // Medium Grey
    },
  },
  typography: {
    fontFamily: 'Roboto, sans-serif',
    h4: {
      fontWeight: 600,
      color: '#1976d2',
    },
    h6: {
      fontWeight: 500,
      color: '#333333',
    },
    body1: {
      color: '#555555',
    },
  },
  components: {
    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#1976d2', // Sidebar background
          color: '#ffffff', // Sidebar text
        },
      },
    },
    MuiListItemText: {
      styleOverrides: {
        primary: {
          fontSize: '1rem',
        },
      },
    },
    MuiTypography: {
      styleOverrides: {
        root: {
          fontFamily: 'Roboto, sans-serif',
        },
      },
    },
  },
});

export default theme;
