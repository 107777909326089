import React from 'react';
import { format, parseISO } from 'date-fns';
import { Card, CardContent,Typography, Box } from '@mui/material';
import colors from '../components/color';

interface OKR {
  _id: string; // Ensure the _id field is included
  Goal: string;
  TimePeriod?: string;
  Comments?: string;
  Status: string;
}

interface MyOKRTileProps {
  okr: OKR;
  onClick: () => void; 
}

const MyOKRTile: React.FC<MyOKRTileProps> = ({ okr, onClick }) => {
  // Helper function to format the date
  const formatDate = (dateString: string): string => {
    const date = parseISO(dateString);
    return format(date, 'd-MMM-yyyy');
  };

  const getStatusColor = () => {
    switch (okr.Status) {
      case 'Green':
        return colors.Green;
      case 'Amber':
        return colors.Amber;
      case 'Red':
        return colors.Red;
      default:
        return 'inherit';
    }
  };


  return (
    <Card
      onClick={onClick}
      sx={{
        cursor: 'pointer',
        height: 'auto',
        minHeight: '140px',
        display: 'flex',
        flexDirection: 'column',
        borderLeft: `4px solid ${getStatusColor()}`,
        '&:hover': {
          transform: 'translateY(-2px)',
          boxShadow: 3,
        }
      }}
    >
      <CardContent sx={{ 
      p: 2,
      "&:last-child": { pb: 2 }, 
      display: 'flex',
      flexDirection: 'column',
      height: '100%'
      }}>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 'medium',
            fontSize: '0.95rem',
            lineHeight: 1.3,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            display: '-webkit-box',
            WebkitLineClamp: 2,
            WebkitBoxOrient: 'vertical',
            mb: 2
          }}
        >
          {okr.Goal}
        </Typography>

        <Box sx={{ 
        mt: 'auto',
        display: 'flex',
        flexDirection: 'column',
        gap: 1
      }}>
        {okr.TimePeriod && (
          <Typography 
            variant="body2" 
            sx={{ 
              color: 'text.secondary',
              fontSize: '0.875rem',
              display: 'flex',
              alignItems: 'center',
              gap: 0.5
            }}
          >
            <span style={{ fontWeight: '500' }}>Due:</span> {formatDate(okr.TimePeriod)}
          </Typography>
        )}

          {okr.Comments && (
            <Typography
              variant="body2"
              color="text.secondary"
              sx={{
                fontSize: '0.875rem',
                mt: 1,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                display: '-webkit-box',
                WebkitLineClamp: 2,
                WebkitBoxOrient: 'vertical',
                fontStyle: 'italic'
              }}
            >
              Comments: {okr.Comments}
            </Typography>
          )}
        </Box>
      </CardContent>
    </Card>
  );
};


export default MyOKRTile;
