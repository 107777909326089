import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import supabase from './supabase';

const ResetPassword: React.FC = () => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [loading, setLoading] = useState(true); // New state to track loading
  const navigate = useNavigate();

  useEffect(() => {
    const setSessionAndValidateToken = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const accessToken = urlParams.get('access_token');

      if (!accessToken) {
        setError('No access token found. Please ensure you are using the correct reset password link.');
        setLoading(false); // Stop loading if no token found
      } else {
        const { error } = await supabase.auth.setSession({
          access_token: accessToken,
          refresh_token: '', // You may not have the refresh token, so pass an empty string here
        });

        if (error) {
          setError('Failed to set session: ' + error.message);
        }

        setLoading(false); // Stop loading after session is set
      }
    };

    setSessionAndValidateToken();
  }, []);

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const { data, error } = await supabase.auth.updateUser({ password });

    if (error) {
      setError('Failed to reset password: ' + error.message);
    } else {
      setSuccess('Password reset successfully. Redirecting to login...');
      setTimeout(() => {
        navigate('/login');
      }, 3000);
    }
  };

  return (
    <div>
      <h2>Reset Password</h2>
      {loading ? (
        <p>Loading...</p>
      ) : (
        <>
          {error && <p style={{ color: 'red' }}>{error}</p>}
          {success && <p style={{ color: 'green' }}>{success}</p>}
          <form onSubmit={handleSubmit}>
            <input
              type="password"
              placeholder="New password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <button type="submit">Reset Password</button>
          </form>
        </>
      )}
    </div>
  );
};

export default ResetPassword;
