// src/components/TopBar.tsx
import React, { useEffect, useState } from 'react';
import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import { useLocation } from 'react-router-dom';
import supabase from '../Auth/supabase';
import axios from 'axios';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const apiBaseUrl = process.env.REACT_APP_API_BASE_URL || 'https://ontrakk.com';

const drawerWidth = 240; // Same value as in Layout

const TopBar: React.FC = () => {
  const location = useLocation();
  const [userName, setUserName] = useState<string>('User');

  useEffect(() => {
    const fetchUserName = async () => {
      try {
        const {
          data: { session },
        } = await supabase.auth.getSession();
        const token = session?.access_token;
        const userEmail = session?.user?.email;

        if (token && userEmail) {
          // Fetch all users associated with the company
          const response = await axios.get(`${apiBaseUrl}/api/okr-owners`, {
            headers: {
              Authorization: `Bearer ${token}`,
              Accept: 'application/json',
            },
          });

          const owners = response.data;

          // Find the current user by matching the email
          const currentUser = owners.find(
            (owner: any) => owner.email === userEmail
          );

          if (currentUser && currentUser.name) {
            setUserName(currentUser.name);
          } else {
            setUserName('User');
          }
        } else {
          setUserName('User');
        }
      } catch (error) {
        console.error('Failed to fetch user name:', error);
        setUserName('User');
      }
    };

    fetchUserName();
  }, []);

  // Map paths to page titles
  const pageTitles: { [key: string]: string } = {
    '/dashboard': 'Dashboard',
    '/my-okrs': 'My OKRs',
    '/okr-relationships': 'OKR Relationships',
    '/focus-okrs': 'Focus Areas',
    '/previous-okrs': 'Previous OKRs',
  };

  const pageTitle = pageTitles[location.pathname] || 'Page';

  return (
    <AppBar
      position="fixed"
      elevation={0}
      sx={{
        zIndex: (theme) => theme.zIndex.drawer + 1,
        backgroundColor: '#ffffff', // Same color as the main app
        color: '#333333',
        marginLeft: `${drawerWidth}px`,
        width: `calc(100% - ${drawerWidth}px)`,
        borderBottom: '1px solid #e0e0e0', // Bottom border
      }}
    >
      <Toolbar>
        <Typography variant="h6" sx={{ flexGrow: 1, fontWeight: 'bold' }}>
          {pageTitle}
        </Typography>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <AccountCircleIcon sx={{ mr: 1 }} />
          <Typography variant="body1">{userName}</Typography>
        </Box>
      </Toolbar>
    </AppBar>
  );
};

export default TopBar;
