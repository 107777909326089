import React from 'react';

const SlackInstalled: React.FC = () => {
  return (
    <div className="flex items-center justify-center min-h-screen bg-gray-50">
      <div className="p-12 bg-white rounded-lg shadow-lg max-w-lg w-full m-4">
        <div className="flex justify-center mb-8">
        </div>
        <div className="mx-8"> {/* margin container */}
        <h1 className="text-3xl font-bold text-center mb-6 text-gray-800">
          Installation Successful!
        </h1>
        <p className="text-xl text-center text-gray-600 mb-8">
        You have successfully installed OnTrakk! Go back to Slack and start setting your OKRs!
        </p>
        <a 
          href="slack://open"
          className="block w-full py-3 px-4 bg-[#4A154B] hover:bg-[#611f64] text-white text-center rounded-lg font-medium transition-colors"
        >
          Return to Slack
        </a>
      </div>
      </div>
    </div>
  );
};

export default SlackInstalled;