// src/components/FokusOKRTiles.tsx

import React from 'react';
import { OKR } from '../types';
import { Card, CardContent, Typography, Box } from '@mui/material';
import { format, parseISO } from 'date-fns';

interface FokusOKRTileProps {
 okr: OKR;
 onClick?: () => void;
 isCompleted?: boolean;
 isFailed?: boolean;
}

const FokusOKRTile: React.FC<FokusOKRTileProps> = ({ okr, onClick,isCompleted,
  isFailed}) => {
 const formatDate = (dateString: string): string => {
   const date = parseISO(dateString);
   return format(date, 'd-MMM-yyyy');
 };

 const getStatusColor = () => {
  if (isCompleted) return '#4caf50';
  if (isFailed) return '#f44336';
  if (okr.Status === 'Red') return '#f44336';  
  if (okr.Status === 'Amber') return '#ff9800'; 
  return 'inherit';
};


 return (
   <Card
     onClick={onClick}
     sx={{
       cursor: onClick ? 'pointer' : 'default',
       height: 'auto',
       minHeight: '160px',
       display: 'flex',
       flexDirection: 'column',
       borderLeft: `4px solid ${getStatusColor()}`,
       '&:hover': {
         transform: 'translateY(-2px)',
         boxShadow: 3,
         transition: 'all 0.2s ease-in-out'
      }
     }}
   >
     <CardContent sx={{ 
       flexGrow: 1, 
       display: 'flex', 
       flexDirection: 'column',
       gap: 1 
     }}>
       <Typography
         variant="subtitle1"
         sx={{
           fontWeight: 'medium',
           fontSize: '0.95rem',
           lineHeight: 1.3,
           overflow: 'hidden',
           textOverflow: 'ellipsis',
           display: '-webkit-box',
           WebkitLineClamp: 2,
           WebkitBoxOrient: 'vertical',
           mb: 1
         }}
       >
         {okr.Goal}
       </Typography>

       <Box sx={{ mt: 'auto' }}>
         {okr.AssigneeName && okr.AssigneeName !== 'Unassigned' && (
           <Typography 
             variant="body2" 
             color="text.secondary"
             sx={{ 
               fontSize: '0.875rem', 
               mb: 0.5,
               display: 'flex',
               alignItems: 'center' 
             }}
           >
             Owner: {okr.AssigneeName}
           </Typography>
         )}

         {okr.Team && (
           <Typography 
             variant="body2" 
             color="text.secondary"
             sx={{ 
               fontSize: '0.875rem', 
               mb: 0.5 
             }}
           >
             Team: {okr.Team}
           </Typography>
         )}

          <Typography 
            variant="body2" 
            color="text.secondary"
            sx={{ fontSize: '0.875rem', mb: 0.5 }}
          >
            {isCompleted && okr.CompletionDate && (
              `Completed: ${formatDate(okr.CompletionDate)}`
            )}
            {isFailed && okr.FailureDate && (
              `Failed: ${formatDate(okr.FailureDate)}`
            )}
            {!isCompleted && !isFailed && okr.TimePeriod && (
              `Due: ${formatDate(okr.TimePeriod)}`
            )}
          </Typography>

         {okr.Comments && (
           <Typography
             variant="body2"
             color="text.secondary"
             sx={{
               fontSize: '0.875rem',
               mt: 1,
               overflow: 'hidden',
               textOverflow: 'ellipsis',
               display: '-webkit-box',
               WebkitLineClamp: 2,
               WebkitBoxOrient: 'vertical',
               fontStyle: 'italic'
             }}
           >
            Comments: {okr.Comments}
              </Typography>
         )}
       </Box>
     </CardContent>
   </Card>
 );
};

export default FokusOKRTile;