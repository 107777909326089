import React, { useState, useEffect } from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Alert from '@mui/material/Alert';
import { useNavigate } from 'react-router-dom';
import supabase from './Auth/supabase';

const Login: React.FC = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();

  useEffect(() => {
    const setSupabaseSession = async () => {
      const urlParams = new URLSearchParams(window.location.search);
      const token = urlParams.get('access_token');
      const code = urlParams.get('code');
      const googleError = urlParams.get('error');

      if (googleError) {
        console.error("Error during Google authentication:", googleError);
        setError(`Google authentication failed: ${googleError}`);
        return;
      }

      if (token) {
        const { data: sessionData, error: sessionError } = await supabase.auth.setSession({ access_token: token, refresh_token: '' });
        if (sessionError) {
          console.error('Error setting session:', sessionError);
          setError('Session error: ' + sessionError.message);
        } else {
          localStorage.setItem('authToken', token);
          navigate('/dashboard');
        }
      } else if (code) {
        try {
          const { data, error: exchangeError } = await supabase.auth.exchangeCodeForSession(code);
          if (exchangeError) {
            console.error('Error exchanging authorization code for access token:', exchangeError);
            setError('Failed to authenticate with Google. Please try again.');
          } else {
            const { session } = data;
            localStorage.setItem('authToken', session?.access_token || '');
            navigate('/dashboard');
          }
        } catch (exchangeError) {
          console.error('Error during the code exchange:', exchangeError);
          setError('Failed to authenticate with Google. Please try again.');
        }
      } else if (window.location.search.includes('error')) {
        setError('Failed to authenticate with Google.');
      }
    };

    setSupabaseSession();
  }, [navigate]);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const email = data.get('email') as string;
    const password = data.get('password') as string;

    const { data: session, error } = await supabase.auth.signInWithPassword({ email, password });

    if (error) {
      console.error('Email Sign-In failed:', error);
      setError('Username and/or password not recognized');
    } else {
      localStorage.setItem('authToken', session.session?.access_token || '');
      navigate('/dashboard');
    }
  };

  const handleGoogleLogin = async () => {
    console.log('Initiating Google OAuth flow');
    const { data, error } = await supabase.auth.signInWithOAuth({
      provider: 'google',
      options: {
        redirectTo: 'https://ontrakk.com/dashboard',
        scopes: 'email profile',
        queryParams: {
          prompt: 'select_account',
        },
      },
    });

    if (error) {
      console.error('Google Sign-In failed:', error);
      setError('Google sign-in failed: ' + error.message);
    } else if (data.url) {
      window.location.href = data.url;
    }
  };

  return (
    <ThemeProvider theme={createTheme()}>
      <Container component="main" maxWidth="xs">
        <CssBaseline />
        <Box
          sx={{
            marginTop: 8,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {error && <Alert severity="error">{error}</Alert>}
          <Box component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1 }}>
            <TextField
              margin="normal"
              required
              fullWidth
              id="email"
              label="Email Address"
              name="email"
              autoComplete="email"
              autoFocus
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="password"
              label="Password"
              type="password"
              id="password"
              autoComplete="current-password"
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              Sign In
            </Button>
            <Button
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
              onClick={handleGoogleLogin}  
            >
              Sign In with Google
            </Button>
            <Grid container>
              <Grid item xs>
                <Link href="/forgot-password" variant="body2">
                  Forgot password?
                </Link>
              </Grid>
              <Grid item>
                <Link href="/sign-up" variant="body2">
                  {"Don't have an account? Sign Up"}
                </Link>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
};

export default Login;
