// PasswordResetSuccess.tsx
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const PasswordResetSuccess: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Redirect to login page after 3 seconds
    setTimeout(() => {
      navigate('/login');
    }, 3000);
  }, [navigate]);

  return (
    <div>
      <h2>Password reset successful!</h2>
      <p>You will be redirected to the login page shortly.</p>
    </div>
  );
};

export default PasswordResetSuccess;