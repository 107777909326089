// src/components/MainCards.tsx
import React from 'react';
import { Card, CardContent, Typography, Grid, Box } from '@mui/material';
import StatusPieChart from './PieChart';
import colors from './color';
import { OKR } from '../types'; // Import the OKR interface


// Props for the MainCardsLayout component
interface MainCardsLayoutProps {
  goalType: string;
  okrs: OKR[];
  selectedTeam: string;
  selectedUser: string;
  onOKRClick: (okr: OKR) => void;
}

// Props for the OKROverviewCard component
interface OKROverviewCardProps {
  goalType: string;
  okrs: OKR[];
  selectedTeam: string;
  onOKRClick: (okr: OKR) => void; // Add this line
}

// Props for the OKRStatusOverviewCard component
interface OKRStatusOverviewCardProps {
  okrs: OKR[];
}

// OKROverviewCard component
export function OKROverviewCard({
  goalType,
  okrs,
  selectedTeam,
  onOKRClick, // Destructure the onOKRClick prop
}: OKROverviewCardProps) {
  const nonCompletedOkrs = okrs.filter(
    (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );

  // Group OKRs by Status
  const groupedOkrs: { [key: string]: OKR[] } = {
    Green: [],
    Amber: [],
    Red: [],
    'No Status Update': [],
  };

  nonCompletedOkrs.forEach((okr) => {
    if (okr.Status === 'Green') {
      groupedOkrs.Green.push(okr);
    } else if (okr.Status === 'Amber') {
      groupedOkrs.Amber.push(okr);
    } else if (okr.Status === 'Red') {
      groupedOkrs.Red.push(okr);
    } else {
      groupedOkrs['No Status Update'].push(okr);
    }
  });

  let title = `${goalType} OKRs`;
  if (goalType === 'Team' && selectedTeam !== 'All Teams') {
    title = `${selectedTeam} Team OKRs`;
  }

  return (
    <Card sx={{ 
      height: '100%',
      borderRadius: 2,
      boxShadow: 1,
      '&:hover': {
        boxShadow: 2,
        transition: 'box-shadow 0.3s ease-in-out'
      }
    }}>
      <CardContent sx={{ p: 3 }}>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          {title}
        </Typography>
        
        {nonCompletedOkrs.length > 0 ? (
          <>
            {['Green', 'Amber', 'Red', 'No Status Update'].map((status) =>
              groupedOkrs[status].length > 0 ? (
                <Box key={status} sx={{ mt: 3 }}>
                  {/* Status Header with Dot */}
                  <Typography
                    variant="subtitle1"
                    fontWeight="bold"
                    sx={{
                      color: colors[status] || 'text.primary',
                      display: 'flex',
                      alignItems: 'center',
                      gap: 1,
                      mb: 2
                    }}
                  >
                    <Box
                      sx={{
                        width: 12,
                        height: 12,
                        borderRadius: '50%',
                        backgroundColor: colors[status] || 'grey.300'
                      }}
                    />
                    {status} OKRs ({groupedOkrs[status].length})
                  </Typography>
   
                  {/* OKR List Items */}
                  {groupedOkrs[status].map((okr, index) => (
                    <Box
                      key={index}
                      sx={{
                        mt: 1.5,
                        cursor: 'pointer',
                        '&:hover': {
                          backgroundColor: 'rgba(0, 0, 0, 0.02)',
                          borderRadius: 1,
                        },
                        transition: 'background-color 0.2s ease-in-out'
                      }}
                      onClick={() => onOKRClick(okr)}
                    >
                      <Typography
                        variant="body1"
                        sx={{
                          fontSize: '0.95rem',
                          lineHeight: 1.4,
                          p: 1,
                          pl: 2,
                          borderLeft: 3,
                          borderColor: colors[status] || 'grey.300'
                        }}
                      >
                        {okr.Goal}
                      </Typography>
                    </Box>
                  ))}
                </Box>
              ) : null
            )}
          </>
        ) : (
          <Box 
            sx={{ 
              display: 'flex', 
              justifyContent: 'center', 
              alignItems: 'center',
              height: 200,
              backgroundColor: 'grey.50',
              borderRadius: 1,
              mt: 2
            }}
          >
            <Typography variant="body1" color="text.secondary">
              No OKRs found.
            </Typography>
          </Box>
        )}
      </CardContent>
    </Card>
   );
}

// OKRStatusOverviewCard component
function OKRStatusOverviewCard({ okrs }: OKRStatusOverviewCardProps) {
  const nonCompletedOkrs = okrs.filter(
    (okr) => okr.Status !== 'Completed' && okr.Status !== 'Failed'
  );

  return (
    <Card sx={{ height: '100%' }}>
      <CardContent>
        <Typography variant="h6" fontWeight="bold" gutterBottom>
          Status Overview
        </Typography>
        <StatusPieChart okrs={nonCompletedOkrs} />
      </CardContent>
    </Card>
  );
}

// MainCardsLayout component
export const MainCardsLayout: React.FC<MainCardsLayoutProps> = ({
  goalType,
  okrs,
  selectedTeam,
  selectedUser,
  onOKRClick, 
}) => {
  return (
    <Box sx={{ flexGrow: 1, px: 0 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: '100%', minHeight: 400 }}>
            <OKROverviewCard
              goalType={goalType}
              okrs={okrs}
              selectedTeam={selectedTeam}
              onOKRClick={onOKRClick}
            />
          </Box>
        </Grid>
        <Grid item xs={12} md={6}>
          <Box sx={{ height: '100%', minHeight: 400 }}>
            <OKRStatusOverviewCard okrs={okrs} />
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};
