// src/components/PieChart.tsx
import React from 'react';
import { PieChart, Pie, Cell, Tooltip, Legend, ResponsiveContainer } from 'recharts';

import { Box, Typography } from '@mui/material';
import colors from './color'; // Using your existing colors file


const COLORS = {
  Green: '#00C49F',
  Amber: '#FFBB28',
  Red: '#FF8042',
};

interface CustomizedLabelProps {
  cx: number;
  cy: number;
  midAngle: number;
  innerRadius: number;
  outerRadius: number;
  percent: number;
  index: number;
  payload: { name: string; value: number };
}

interface OKR {
  Status?: string; // Status is optional
}

interface StatusPieChartProps {
  okrs: OKR[];
}
const CustomTooltip: React.FC<any> = ({ active, payload }) => {
  if (active && payload && payload.length) {
    return (
      <Box
        sx={{
          bgcolor: '#fff',
          p: 1.5,
          border: '1px solid #e0e0e0',
          borderRadius: 1,
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}
      >
        <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
          {payload[0].name}: {payload[0].value} OKRs
        </Typography>
        <Typography sx={{ fontSize: '0.875rem', color: 'text.secondary' }}>
          {`${(payload[0].payload.percent * 100).toFixed(0)}% of total`}
        </Typography>
      </Box>
    );
  }
  return null;
};

const CustomizedLabel: React.FC<CustomizedLabelProps> = ({
  cx,
  cy,
  midAngle,
  innerRadius,
  outerRadius,
  percent,
}) => {
  const RADIAN = Math.PI / 180;
  const radius = innerRadius + (outerRadius - innerRadius) * 0.35;
  const x = cx + radius * Math.cos(-midAngle * RADIAN);
  const y = cy + radius * Math.sin(-midAngle * RADIAN);

  if (percent < 0.05) return null; // Don't show labels for very small segments

  return (
    <text
      x={x}
      y={y}
      fill="white"
      textAnchor={x > cx ? 'start' : 'end'}
      dominantBaseline="central"
      style={{
        fontSize: '0.875rem',
        fontWeight: 500
      }}
    >
      {`${(percent * 100).toFixed(0)}%`}
    </text>
  );
};

const CustomLegend: React.FC<any> = (props) => {
  const { payload } = props;
  
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'center',
        gap: 3,
        mt: 2
      }}
    >
      {payload.map((entry: any, index: number) => (
        <Box
          key={`legend-${index}`}
          sx={{
            display: 'flex',
            alignItems: 'center',
            gap: 1
          }}
        >
          <Box
            sx={{
              width: 12,
              height: 12,
              borderRadius: '50%',
              bgcolor: entry.color
            }}
          />
          <Typography sx={{ fontSize: '0.875rem', fontWeight: 500 }}>
            {`${entry.value} (${entry.payload.count} OKRs)`}
          </Typography>
        </Box>
      ))}
    </Box>
  );
};

const StatusPieChart: React.FC<StatusPieChartProps> = ({ okrs }) => {
  const data = [
    { 
      name: 'Green', 
      value: okrs.filter((okr) => okr.Status === 'Green').length,
      color: colors.Green 
    },
    { 
      name: 'Amber', 
      value: okrs.filter((okr) => okr.Status === 'Amber').length,
      color: colors.Amber 
    },
    { 
      name: 'Red', 
      value: okrs.filter((okr) => okr.Status === 'Red').length,
      color: colors.Red 
    },
  ].map(item => ({
    ...item,
    percent: item.value / okrs.length,
    count: item.value
  }));

  return (
    <Box sx={{ width: '100%', height: 400 }}>
      <ResponsiveContainer>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="45%"
            labelLine={false}
            label={CustomizedLabel}
            outerRadius={120}
            innerRadius={60}
            fill="#8884d8"
            dataKey="value"
          >
            {data.map((entry, index) => (
              <Cell 
                key={`cell-${index}`} 
                fill={entry.color}
                stroke="#fff"
                strokeWidth={2}
              />
            ))}
          </Pie>
          <Tooltip content={<CustomTooltip />} />
          <Legend content={<CustomLegend />} />
        </PieChart>
      </ResponsiveContainer>
    </Box>
  );
};

export default StatusPieChart;
