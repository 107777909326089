// Layout.tsx
import React from 'react';
import { Outlet } from 'react-router-dom';
import TopBar from './components/TopBar';
import Sidebar from './components/Navbar';
import { Box } from '@mui/material';

const drawerWidth = 240;

interface LayoutProps {
  logout: () => void;
}

const Layout: React.FC<LayoutProps> = ({ logout }) => (
  <>
    <TopBar />
    <Sidebar logout={logout} />
    <Box
      component="main"
      sx={{
        flexGrow: 1,
        p: 3,
        mt: 8, // Adjust for the height of the TopBar
        ml: `${drawerWidth}px`, // Adjust for the width of the Sidebar
      }}
    >
      <Outlet />
    </Box>
  </>
);

export default Layout;
